

















































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    InsLogo: () => import('@/components/base/InsLogo.vue')
  }
})
export default class InsFooterLayout extends Vue {
  @Prop() footerContact!: object;
}
